import { Fragment } from 'react';
import { isItUndefined, splitTetheredName } from './DataUtil';

export const renderNoResults = (noResultsText) => {
  return (
    <div className={'no-results-text'}>{isItUndefined(noResultsText)}</div>
  );
};

export const renderFilterBox = (filters, filterTextDisplay) => {
  const filteredArray = filters.filter((obj) => !obj.name.includes('(T)'));

  return (
    <Fragment>
      {/* <span className='view-text'>{isItUndefined(children?.field_filter_display_text)}</span> */}
      <span className="view-text">{isItUndefined(filterTextDisplay)}</span>
      <div className="filters-container">
        {filteredArray?.map((item, key) => {
          return (
            <div key={`filter-index-${key}`} className="filter">
              <img src="/images/map-info-location.svg" />
              <span className="filter-label">{item?.name}</span>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export const renderFilterBoxTethered = (filters, filterTextDisplay) => {
  const filteredArray = splitTetheredName(
    filters.filter((obj) => obj.name.includes('(T)'))
  );

  return (
    <Fragment>
      {filteredArray.length > 0 && (
        // <span className='view-text tethered'>{isItUndefined(children?.field_filter_display_text_2)}</span>
        <span className="view-text tethered">
          {isItUndefined(filterTextDisplay)}
        </span>
      )}
      <div className="tethered-filters-container">
        {filteredArray?.map((item, key) => {
          return (
            <div key={`filter-index-${key}`} className="filter">
              <img src="/images/map-info-location.svg" />
              <span className="filter-label">
                {item?.baseName}
                <span className="tethered-font">({item?.tag})</span>
              </span>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export const sortMetros = (array, query) => {
  const sortedArray = array.sort((a, b) => {
    const aMatchIndex = a.metro_name.toLowerCase().indexOf(query);
    const bMatchIndex = b.metro_name.toLowerCase().indexOf(query);

    if (aMatchIndex === -1 && bMatchIndex !== -1) return 1;
    if (aMatchIndex !== -1 && bMatchIndex === -1) return -1;

    if (aMatchIndex !== -1 && bMatchIndex !== -1) {
      if (aMatchIndex < bMatchIndex) return -1;
      if (aMatchIndex > bMatchIndex) return 1;
    }

    return a.metro_name.localeCompare(b.metro_name);
  });

  return sortedArray;
};

export const sortMarkers = (markers) => {
  const sortedDataCenters = markers.sort((a, b) => {
    if (a.text < b.text) return -1;
    if (a.text > b.text) return 1;
    return 0;
  });

  return sortedDataCenters;
};

const calculateDistance = (pointA, pointB) => {
  const rad = (x) => (x * Math.PI) / 180;
  const R = 6378137; // Earth’s mean radius in meter

  const dLat = rad(pointB.latitude - pointA.latitude);
  const dLong = rad(pointB.longitude - pointA.longitude);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(pointA.latitude)) *
      Math.cos(rad(pointB.latitude)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;

  return distance;
};

// export const groupLocations = (locations, threshold = 10000) => {
//     const groups = [];

//     if(!locations){
//         return;
//     }

//     while (locations.length > 0) {
//         const group = [];
//         const baseLocation = locations.shift(); // Remove the first location and use it as base
//         group.push(baseLocation);

//         for (let i = locations.length - 1; i >= 0; i--) {
//             const distance = calculateDistance(baseLocation, locations[i]);

//             if (distance <= threshold) {
//                 group.push(locations.splice(i, 1)[0]); // Remove and add to the group
//             }
//         }

//         groups.push(sortMarkers(group));
//     }

//     return groups;
// };

export const groupLocations = (points, maxDistance) => {
  const matrix = generateDistanceMatrix(points);
  const groups = [];
  const visited = new Array(points.length).fill(false);

  const findGroup = (index, group) => {
    visited[index] = true;
    group.push(points[index]);

    for (let i = 0; i < points.length; i++) {
      if (!visited[i] && matrix[index][i] <= maxDistance) {
        findGroup(i, group);
      }
    }
  };

  for (let i = 0; i < points.length; i++) {
    if (!visited[i]) {
      const group = [];
      findGroup(i, group);
      // groups.push(sortMarkers(group));
      groups.push(group);
    }
  }

  return groups;
};

const generateDistanceMatrix = (points) => {
  const matrix = Array(points.length)
    .fill(null)
    .map(() => Array(points.length).fill(0));

  for (let i = 0; i < points.length; i++) {
    for (let j = i + 1; j < points.length; j++) {
      const distance = calculateDistance(points[i], points[j]);
      matrix[i][j] = distance;
      matrix[j][i] = distance;
    }
  }

  return matrix;
};

export const calculateMapCenter = (data) => {
  if (!data || !data.length) {
    return null;
  }

  const totalLatitude =
    data.reduce((total, currentItem) => total + currentItem.latitude, 0) /
    data.length;
  const totalLongitude =
    data.reduce((total, currentItem) => total + currentItem.longitude, 0) /
    data.length;

  const regionColors = [...new Set(data.map((item) => item?.regionColor))];

  return {
    latitude: totalLatitude,
    longitude: totalLongitude,
    regionColor: regionColors[0],
  };
};

// export const getHoveredStyle = (markerCount) => {
//     switch (markerCount) {
//         case 2:
//             // return { width: 120, height: 120, transform: `scale(${120/96})` };
//             return { width: 120, height: 120, transform: `scale(1)` };
//         case 3:
//             return { width: 132, height: 132, transform: `scale(1)` };
//         case 4:
//             return { width: 144, height: 144, transform: `scale(1)` };
//         default:
//             return { width: 180, height: 180, transform: `scale(1)` };
//     }
// };

export const getHoveredStyle = (markerCount) => {
  let size;
  switch (markerCount) {
    case 2:
      size = 120;
      break;
    case 3:
      size = 132;
      break;
    case 4:
      size = 144;
      break;
    default:
      size = 180;
      break;
  }
  const marginShift = (96 - size) / 2;
  return {
    width: `${size}px`,
    height: `${size}px`,
    marginLeft: `${marginShift}px`,
    marginTop: `${marginShift}px`,
  };
};

export const getFacilitiesPosition = (numberOfFacilities, index) => {
  const styles = {
    2: {
      1: { top: '15%', left: '20%' },
      2: { bottom: '15%', right: '20%' },
    },
    3: {
      1: { top: '20%', right: '15%' },
      2: { bottom: '10%' },
      3: { top: '20%', left: '15%' },
    },
    4: {
      1: { top: '8%' },
      2: { right: '8%' },
      3: { bottom: '8%' },
      4: { left: '8%' },
    },
    5: {
      1: { top: '8%' },
      2: { right: '8%' },
      3: { bottom: '8%' },
      4: { left: '8%' },
    },
    6: {
      1: { top: '5%' },
      2: { right: '10%', top: '20%' },
      3: { right: '10%', bottom: '20%' },
      4: { bottom: '5%' },
      5: { left: '10%', bottom: '20%' },
      6: { left: '10%', top: '20%' },
    },
    7: {
      1: { top: '5%' },
      2: { right: '10%', top: '20%' },
      3: { right: '10%', bottom: '20%' },
      4: { bottom: '5%' },
      5: { left: '10%', bottom: '20%' },
      6: { left: '10%', top: '20%' },
    },
    8: {
      1: { top: '5%' },
      2: { right: '14%', top: '14%' },
      3: { right: '5%' },
      4: { right: '14%', bottom: '14%' },
      5: { bottom: '5%' },
      6: { left: '14%', bottom: '14%' },
      7: { left: '5%' },
      8: { left: '14%', top: '14%' },
    },
    9: {
      1: { top: '5%' },
      2: { right: '14%', top: '14%' },
      3: { right: '5%' },
      4: { right: '14%', bottom: '14%' },
      5: { bottom: '5%' },
      6: { left: '5%' },
      7: { left: '14%', bottom: '14%' },
      8: { left: '14%', top: '14%' },
    },
    default: {
      1: { top: '5%' },
      2: { right: '14%', top: '14%' },
      3: { right: '5%' },
      4: { right: '14%', bottom: '14%' },
      5: { bottom: '5%' },
      6: { left: '5%' },
      7: { left: '14%', bottom: '14%' },
      8: { left: '14%', top: '14%' },
    },
  };

  const selectedStyles =
    numberOfFacilities >= 9 ? styles.default : styles[numberOfFacilities];
  return selectedStyles ? selectedStyles[index] || {} : {};
};

export const getRadiusTresholdValue = (zoom) => {
  switch (zoom) {
    case 10:
      return 10000;
    // return 15000;
    case 11:
      return 5000;
    case 12:
      return 3000;
    case 13:
      return 1000;
    case 14:
      return 500;
    case 15:
      return 200;
    case 16:
    case 17:
    case 18:
      return 50;
    case 19:
      return 10;
    case 20:
    case 21:
    case 22:
      return 0;
    default:
      return 10000;
  }
};

export const findItemByText = (data, searchText) => {
  if (!searchText) {
    return null;
  }

  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].length; j++) {
      if (data[i][j].text.toLowerCase() === searchText.toLowerCase()) {
        return { index: i, childIndex: j };
      }
    }
  }

  return null;
};
